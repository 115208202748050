import BaseWidget from './base_widget'

class LanguagesDropdown extends BaseWidget {
  static async init () {
    return new LanguagesDropdown()
  }

  constructor () {
    super('.google-translate .dropdown-menu a')
  }

  enableTranslation () {
    super.enableTranslation()

    const $selectedElem = this.$languageLinks.siblings(`[data-lang='${this.selectedLanguage()}']`)

    if ($selectedElem[0] && !$('body').hasClass('body-s1606')) {
      $('#google_translate_dropdown').html($selectedElem[0].innerHTML)
    }
  }
}

export default LanguagesDropdown
