import 'flag-icon/svg/ca/québec.svg'
import 'flag-icon/svg/es/catalonia.svg'

import LanguagesList from 'languages_widget/scripts/languages_list'
import LanguagesDropdown from 'languages_widget/scripts/languages_dropdown'

importAll(require.context('svg-country-flags/svg/', true, /\.svg/))
importAll(require.context('flag-icon/svg/country-4x3/', true, /\.svg/))
importAll(require.context('listings/images', true, /\.(svg|png|gif)/))

function importAll (r) {
  return r.keys().map(r)
}

$(function () {
  LanguagesList.init()
  LanguagesDropdown.init()
})
