/* global google */
/* global I18n */

import Cookies from 'js-cookie'
import WebsiteConfigs from 'website_configs/website_configs'

const GOOGLE_TRANSLATE_SRC_URL = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'

class BaseWidget {
  constructor (linksSelector) {
    this.primaryLanguage = WebsiteConfigs.primaryLanguageCode

    this.$languageLinks = $(linksSelector)

    if (this.$languageLinks.length > 0) {
      if (this.isTranslationEnabled()) {
        this.enableTranslation()
      }

      this._bindCallbacks()
    }
  }

  isTranslationEnabled () {
    if (this.selectedLanguage()) {
      return this.selectedLanguage() !== this.primaryLanguage
    }
  }

  selectedLanguage () {
    if (window.location.hash.substr(1).match(/googtrans/)) {
      return decodeURIComponent(window.location.hash.substr(1)).match(/googtrans\(\w{2}\|([\w-]{2,5})\)/)[1]
    } else if (Cookies.get('googtrans')) {
      return Cookies.get('googtrans').match(/\/[\w-]{2,5}\/([\w-]{2,5})/)[1]
    }
  }

  enableTranslation () {
    window.googleTranslateElementInit = this.initTranslate

    this.loadScript()
  }

  initTranslate () {
    return new google.translate.TranslateElement({
      pageLanguage: WebsiteConfigs.primaryLanguageCode,
      layout: google.translate.TranslateElement.InlineLayout.SIMPLE
    })
  }

  loadScript () {
    const googleTranslateScript = document.createElement('script')

    googleTranslateScript.type = 'text/javascript'
    googleTranslateScript.async = true
    googleTranslateScript.src = GOOGLE_TRANSLATE_SRC_URL

    document.getElementsByTagName('head')[0].appendChild(googleTranslateScript)
  }

  _bindCallbacks () {
    this.$languageLinks.on('click', (e) => {
      e.preventDefault()

      const lang = $(e.target).parent().data('lang') || $(e.target).data('lang')
      const value = `/${this.primaryLanguage}/${lang === 'zh' ? 'zh-CN' : lang}`
      const dataQueryParams = $('code[data-query-params]').attr('data-query-params')
      const prevLang = I18n.lang
      const hostName = window.location.hostname

      Cookies.set('googtrans', value, { path: '/' })
      Cookies.set('googtrans', value, { path: '/', domain: hostName })
      Cookies.set('googtrans', value, { path: '/', domain: hostName.replace(/^www/, '') })
      Cookies.set('googtrans', value, { path: '/', domain: hostName.replace(/^.+\.*(machineryhost.com)/, '$1') })
      Cookies.set('googtrans', value, { path: '/', domain: hostName.replace(/^.+\.*(machineryhost.com)/, '.$1') })
      Cookies.set('googtrans', value, { path: '/', domain: hostName.replace(/^.+\.*(machinio.(dev|com))/, '$1') })
      Cookies.set('googtrans', value, { path: '/', domain: hostName.replace(/^.+\.*(machinio.(dev|com))/, '.$1') })
      Cookies.set('googtrans', value, { path: '/', domain: hostName.replace(/^www\./, '') })
      Cookies.set('googtrans', value, { path: '/', domain: hostName.replace(/^www\.m\./, '') })

      if (hostName.split('.').slice(-2).join('.') !== 'machinio.com') {
        Cookies.set('googtrans', value, { path: '/', domain: hostName.split('.').slice(-2).join('.') })
      }

      const listingsPathRegex = /(annunci|listings|angebote|anuncios|annonce(s|r)|advertenties|ogloszenia)\/(\d+)/
      const alternateUrl = $(`link[rel="alternate"][hreflang="${lang}"]`).attr('href')
      const listingId = window.location.href.match(listingsPathRegex) ? window.location.href.match(listingsPathRegex)[3] : null
      const controllerName = WebsiteConfigs.controllerName

      if (alternateUrl) {
        window.location.href = new URL(alternateUrl, location).pathname
      } else if (dataQueryParams || listingId || controllerName.match(/landing|categories|manufacturers|pages/)) {
        $.ajax({
          url: `/change_languages`,
          method: 'GET',
          dataType: 'json',
          data: { target_lang: lang,
            controller_name: controllerName,
            listing_id: listingId,
            path_name: window.location.pathname,
            lang: prevLang,
            ...jQuery.parseJSON(dataQueryParams || '{}') }
        }).done((data) => {
          window.location.href = data.path
        }).fail(() => {
          window.location.href = '/'
        })
      } else if (WebsiteConfigs.manualLanguages.length === 1) {
        window.location.href = window.location.href.replace(/\/\w{2}\//, '/')
      } else {
        window.location.href = '/'
      }
    })
  }
}

export default BaseWidget
