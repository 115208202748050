import BaseWidget from './base_widget'

class LanguagesList extends BaseWidget {
  static async init () {
    return new LanguagesList()
  }

  constructor () {
    super('.google-translate-list a')
  }
}

export default LanguagesList
